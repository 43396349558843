import { useAuthInfo } from '@propelauth/react';

import type { Permission } from '~/ts-types';

import { useMetadata } from './use-metadata';

const HOUNDDOG_ORG_ID = '3330c184-078c-4341-b573-6e98c49440f7';
const HOUNDDOG_ORG_NAME = 'HoundDog';

const useAuthorization = () => {
  const { metadata } = useMetadata();
  const orgId = metadata?.currentUser.orgId;
  const orgName = metadata?.currentUser.orgName;
  const authInfo = useAuthInfo();

  const hasPermission = (permission: Permission) => {
    if (!orgId) {
      return false;
    }
    return !!authInfo.accessHelper?.hasPermission(orgId, permission);
  };

  const isCurrentOrgHoundDog =
    orgName === HOUNDDOG_ORG_NAME || orgId === HOUNDDOG_ORG_ID;

  return { isCurrentOrgHoundDog, hasPermission } as const;
};

export { useAuthorization };
